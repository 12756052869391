// extracted by mini-css-extract-plugin
export var arrowAnimation = "Progress-module--arrowAnimation--e311b";
export var black = "Progress-module--black--0ab01";
export var card = "Progress-module--card--58da0";
export var card1 = "Progress-module--card1--44115";
export var card2 = "Progress-module--card2--50e13";
export var card3 = "Progress-module--card3--4f3f7";
export var colGap = "Progress-module--colGap--a9316";
export var concerns = "Progress-module--concerns--c0ce0";
export var description = "Progress-module--description--4527a";
export var heading = "Progress-module--heading--35427";
export var newBtn = "Progress-module--newBtn--99de0";
export var progress = "Progress-module--progress--e1b7a";
export var progressSec = "Progress-module--progressSec--81c52";
export var subDescription = "Progress-module--subDescription--6e546";
export var subHeading = "Progress-module--subHeading--3e715";
export var talkBtn = "Progress-module--talkBtn--6974a";
export var white = "Progress-module--white--db47b";