// extracted by mini-css-extract-plugin
export var aiIcon = "KeyChalangesSolution-module--ai-icon--1dce0";
export var challengeContent = "KeyChalangesSolution-module--challengeContent--ee6cc";
export var challengeRow = "KeyChalangesSolution-module--challengeRow--b6c63";
export var challengesGrid = "KeyChalangesSolution-module--challengesGrid--b68e1";
export var connectingLine = "KeyChalangesSolution-module--connectingLine--2f92d";
export var content = "KeyChalangesSolution-module--content--1e6e4";
export var graphIcon = "KeyChalangesSolution-module--graph-icon--7454b";
export var icon = "KeyChalangesSolution-module--icon--11b1b";
export var iconWrapper = "KeyChalangesSolution-module--iconWrapper--d5b46";
export var keyChallengesContainer = "KeyChalangesSolution-module--keyChallengesContainer--1bb9a";
export var leftBox = "KeyChalangesSolution-module--leftBox--826a3";
export var rightBox = "KeyChalangesSolution-module--rightBox--ea4a0";
export var solutionContent = "KeyChalangesSolution-module--solutionContent--43c5e";
export var title = "KeyChalangesSolution-module--title--ea52c";
export var titleWrapper = "KeyChalangesSolution-module--titleWrapper--eb283";
export var webglIcon = "KeyChalangesSolution-module--webgl-icon--0a2c4";