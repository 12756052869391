import React from "react"
import * as styles from "./Introsection.module.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"

const AIStatisticsTrend = ({ strapiData }) => {
  return (
    <div>
      <div className={styles.sectionContainer}>
        <Container>
          <Row style={{ alignItems: "center" }}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className={styles.headingContainer}
            >
              <h2
                className={styles.headingIntro}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className={styles.headingContainer}
            >
              <p
                className={styles.descriptionText}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={7}
              className={styles.headingContainer}
            >
              <p
                className={styles.paragraphAi}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.bannerImage}>
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/image_5_c2fa5ebf3e.webp"
          }
          alt="laptop"
        />
      </Container>
    </div>
  )
}

export default AIStatisticsTrend
