// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner1-module--arrowAnimation--fb41b";
export var backgroundImg = "Banner1-module--backgroundImg--cf5d4";
export var bannerCurve = "Banner1-module--bannerCurve--bfa71";
export var bannerHeading = "Banner1-module--bannerHeading--2508d";
export var bannerText = "Banner1-module--bannerText--61ec0";
export var btn = "Banner1-module--btn--4ac23";
export var btne = "Banner1-module--btne--cef19";
export var curve = "Banner1-module--curve--3339e";
export var dedicated = "Banner1-module--dedicated--33771";
export var dedicatedBanner = "Banner1-module--dedicatedBanner--c423e";
export var dedicatedHeadingStatic = "Banner1-module--dedicatedHeadingStatic--7dd6f";
export var description = "Banner1-module--description--f8626";
export var descriptionText = "Banner1-module--descriptionText--ab189";
export var headingIntro = "Banner1-module--headingIntro--9a516";
export var innerDedicated = "Banner1-module--innerDedicated--4f1ed";
export var mainTitle = "Banner1-module--mainTitle--3863c";
export var newBtn = "Banner1-module--newBtn--9518f";
export var paragraphAi = "Banner1-module--paragraphAi--b6f8d";
export var sliderButtons = "Banner1-module--sliderButtons--e8670";
export var subTitle = "Banner1-module--subTitle--c366e";